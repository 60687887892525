const video = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
		console.log('video')
	},

	bindUI: function() {
		this.ui.$document = $(document);
		this.ui.$subtitlesToggle = $('.js-video-subtitles-toggle');
		this.ui.$subtitles = $('.js-video-subtitles');
	},

	bindEvents: function() {
        this.ui.$subtitlesToggle.on('click', this.toggleSub.bind(this))
    },
	
	toggleSub: function(e) {
        console.log('toggle sub')
		let $this = $(e.currentTarget)
        let $video = $this.closest('.js-video')
        let $sub = $video.find('.js-video-subtitles')
        if ($video.hasClass('has-subtitles')) {
            $video.removeClass('has-subtitles')
            $sub.slideUp(250)
        } else {
            $video.addClass('has-subtitles')
            $sub.slideDown(250)
        }
	},

};

module.exports = video;