const postsSlider = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
		console.log('js-posts-slider')
		this.initSlider();
	},

	bindUI: function() {
		this.ui.$document = $(document);
		this.ui.$jobsSlider = $('.js-posts-slider');
	},

	bindEvents: function() {

    },
	
	initSlider: function() {
		this.ui.$jobsSlider.not('.slick-initialized').each((i, slider) => {
		console.log('slider init')
        $(slider).slick({
				infinite: false,
				dots: false,
				arrows: true,
				speed: 400,
				slidesToShow: 3,
                centerMode: false,
                responsive: [
                    {
                      breakpoint: 800,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: false,
                        arrows: true,
                        centerMode: true,
                      }
                    }
                ]
			});
		});
	},

};

module.exports = postsSlider;