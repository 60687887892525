const header = {
	ui: {},

	init: function() {
        console.log('header');

		this.bindUI();
		this.bindEvents();
        this.ui.$mainMenu.addClass('is-disabled');
	},

	bindUI: function bindUI() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);
		this.ui.$body = $("body");

		this.ui.$header = $(".js-header");
		this.ui.$wrapper = $(".js-wrapper");

		this.ui.$menuToggle = $(".js-toggle-menu");
		this.ui.$menuContainer = $(".js-menu-container");
		this.ui.$menuNav = $(".js-menu-nav");
		this.ui.$underlay = $(".js-header-underlay");

        this.ui.$mainMenu = $('.js-mainMenu-list');
        this.ui.$mainMenuItem = $('.js-mainMenu-item');

        this.ui.$submenuOpen = $(".js-mainMenu-subMenu-open");
        this.ui.$submenuClose = $(".js-mainMenu-subMenu-close");

        this.ui.$search = $('.js-mainMenu-search');
        this.ui.$searchToggle = $('.js-mainMenu-search-toggle');
        this.ui.$searchTab = $('.js-mainMenu-search-tab');
	},

	bindEvents: function bindEvents() {
		this.ui.$window.on('scroll', this.menuOnScroll.bind(this));
		
		this.ui.$menuToggle.on('click', this.menuMobile.bind(this));
        this.ui.$submenuOpen.on('click', this.submenuMobileOpen.bind(this));
        this.ui.$submenuClose.on('click', this.submenuMobileClose.bind(this));
        this.ui.$underlay.on('click', this.menuMobile.bind(this))
        this.ui.$header.on('click', this.searchClose.bind(this));
        this.ui.$searchToggle.on('click', this.searchOpen.bind(this));
        this.ui.$searchTab.on('click', e => e.stopPropagation());
        this.ui.$mainMenuItem.on('mouseleave', this.enableMenu.bind(this));
        this.ui.$wrapper.on('mouseleave', this.enableMenu.bind(this));
    },

    enableMenu: function() {
        if (this.ui.$mainMenu.hasClass('is-disabled')) {
            this.ui.$mainMenu.removeClass('is-disabled');
        }
    },

	searchOpen: function(e) {
        console.log('searchOpen')
		this.ui.$search.addClass('is-active')
        this.ui.$search.find('input[type="text"]').focus()
        e.stopPropagation()
	},

	searchClose: function(e) {
        console.log('searchClose')
        if (this.ui.$search.hasClass('is-active')) {
            this.ui.$search.removeClass('is-active')
        }
	},

	menuOnScroll: function(e) {
		let $this = $(e.target);
		
		if($this.scrollTop() > 70 && !this.ui.$header.hasClass("is-fixed")) {
			$(".js-header").addClass("is-fixed");
		}
		if($this.scrollTop() < 70 && this.ui.$header.hasClass("is-fixed")) {
			$(".js-header").removeClass("is-fixed");
		}
	},

    menuMobile: function(e) {
		let $this = this.ui.$menuToggle;
		
		if($this.hasClass("is-active")){
			this.ui.$body.removeClass("disable-scroll");
            this.ui.$header.removeClass("is-open");
            $this.removeClass("is-active");
			this.ui.$menuContainer.removeClass("is-active");
			this.ui.$menuNav.removeClass("is-active");
            this.ui.$underlay.removeClass("is-active");
		} else {
			this.ui.$body.addClass("disable-scroll");
            this.ui.$header.addClass("is-open");
			$this.addClass("is-active");
			this.ui.$menuContainer.addClass("is-active");
			this.ui.$menuNav.addClass("is-active");
            this.ui.$underlay.addClass("is-active");
		}
    },

    submenuMobileOpen: function(e) {
		let $this = $(e.currentTarget);
        let $item = $this.closest('.js-mainMenu-item')
		
        $item.addClass('is-open')
    },

    submenuMobileClose: function(e) {
		let $this = $(e.currentTarget);
        let $item = $this.closest('.js-mainMenu-item')
		
        $item.removeClass('is-open')
    }

};

module.exports = header;
