// Import jQuery => utilise celui de wp de base, evite duplicat de jquery
import jQuery from "jquery";
// export for others scripts to use
window.$ = window.jQuery = jQuery;

// Require modules
import browserClassHelpers from './modules/browser-class-helpers.js';
import header from './modules/header.js';
//import materialForm from './modules/material-form.js';
//import navScrollHighlight from './modules/nav-scroll-highlight.js';
//import popin from './modules/popin.js';
//import scrollAnchor from './modules/scroll-anchor.js';
//import ssTooltip from './modules/tooltip';
//import shareLinks from './modules/shareLinks';
import faq from './modules/faq';
import sidebar from './modules/sidebar';
import summary from './modules/summary';
import comment from './modules/comment';
import video from './modules/video';
import postsSlider from './modules/postsSlider';
import multiSelect from './modules/multiSelect';


// import checkVisible from './modules/checkVisible';

// Import Slick
import 'slick-carousel';

$(function(){
	$("html").removeClass("no-js");

	browserClassHelpers.init();
	header.init();
    
    
    if ($('.js-sidebar').length) {
        sidebar.init();
    }
    if ($('.js-summary').length) {
        summary.init();
    }
    if ($('.js-comment').length) {
        comment.init();
    }
    if ($('.js-video').length) {
        video.init();
    }
    
	/*

	shareLinks.init();
	
	if ($(".materialForm").length) {
		materialForm.init();
	}
	if ($(".js-scroll-anchor").length) {
		scrollAnchor.init();
	}
	if ($(".js-nav-link").length) {
		navScrollHighlight.init();
	}
	if ($(".js-popin").length) {
		popin.init();
	}
	if ($('.js-tooltip').length) {
		ssTooltip.init();
	}
    */

	if ($('.js-posts-slider').length) {
		postsSlider.init();
	}
	if ($(".js-faq").length) {
		faq.init();
	}
	if($(".js-multi-select").length > 0){
		multiSelect.init();
	}
});
