const comment = {
	ui: {},

	init: function() {
        console.log('comment');

		this.bindUI();
		this.bindEvents();
        this.setSectionBreakpoints();
	},

	bindUI: function bindUI() {
		this.ui.$document = $(document);
		this.ui.$window = $(window);

		this.ui.$comment = $(".js-comment");
		this.ui.$replyButton = $(".js-comment-replyButton");
		this.ui.$replyForm = $(".js-comment-replyForm");
	},

	bindEvents: function bindEvents() {
		this.ui.$replyButton.on('click', this.reply.bind(this));
	},

    reply: function(e) {
        console.log('reply')
        let $this = $(e.currentTarget)
        let $parentComment = $this.parents('.js-comment').last()
        let $replyForm = $parentComment.find('.js-comment-replyForm')
        if ($parentComment.hasClass('is-active')) {
            this.ui.$comment.removeClass('is-active')
            this.ui.$replyForm.slideUp(200)
        } else {
            this.ui.$comment.removeClass('is-active')
            $parentComment.addClass('is-active')
            this.ui.$replyForm.slideUp(200)
            $replyForm.slideDown(200)
        }
    },


};

module.exports = comment;
