const sidebar = {
	ui: {},

	init: function() {
        console.log('sidebar');

		this.bindUI();
		this.bindEvents();
        this.onPageScroll();
	},

	bindUI: function bindUI() {
		this.ui.$window = $(window);

		this.ui.$layout = $(".js-layout");
		this.ui.$sidebar = $(".js-sidebar");
	},

	bindEvents: function bindEvents() {
        this.ui.$window.on('scroll', this.onPageScroll.bind(this));
	},

	onPageScroll: function() {
        let scrollTop = this.ui.$window.scrollTop()
        let scrollBottom = this.ui.$window.scrollTop() + this.ui.$window.outerHeight()
        let layoutTop = this.ui.$layout.offset().top
        let layoutBottom = this.ui.$layout.offset().top + this.ui.$layout.outerHeight()
        let sidebarTop = this.ui.$sidebar.offset().top 
        let sidebarBottom = this.ui.$sidebar.offset().top + this.ui.$sidebar.outerHeight()

        if (scrollTop > (layoutTop - 70)) {
            this.ui.$sidebar.addClass('is-fixed')
        } else {
            this.ui.$sidebar.removeClass('is-fixed')
        }

        if (scrollBottom > (layoutBottom - 50)) {
            if ( sidebarBottom > (layoutBottom - 50)) {
                this.ui.$sidebar.addClass('is-bottom')
            }
            if (scrollTop < (sidebarTop - 70)) {
                this.ui.$sidebar.removeClass('is-bottom')
            }
        } else {
            this.ui.$sidebar.removeClass('is-bottom')
        }
	},

};

module.exports = sidebar;
