const faq = {
	ui: {},

	init: function() {
        console.log('faq');

		this.bindUI();
		this.bindEvents();
	},

	bindUI: function bindUI() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);
		this.ui.$body = $("body");

		this.ui.$faqItem = $(".js-faq-item");

		this.ui.$faqQuestion = $(".js-faq-question");
		this.ui.$faqAnswer = $(".js-faq-answer");
	},

	bindEvents: function bindEvents() {
		this.ui.$faqQuestion.on('click', this.toggleFaq.bind(this));
	},

	toggleFaq: function(e) {
        console.log('click')
        let $faq = $(e.currentTarget).closest('.js-faq')
        let $item = $(e.currentTarget).closest('.js-faq-item')
        if ($item.hasClass('is-active')) {
            $item.find('.js-faq-answer').slideUp(200)
            $item.removeClass('is-active')
        } else {
            $item.find('.js-faq-answer').slideDown(200)
            $item.addClass('is-active')
        }
	},

};

module.exports = faq;
