const summary = {
	ui: {},

	init: function() {
        console.log('summary');

		this.bindUI();
		this.bindEvents();
        this.setSectionBreakpoints();
        this.onPageScroll();
        this.setPageProgress();
	},

	bindUI: function bindUI() {
		this.ui.$document = $(document);
		this.ui.$window = $(window);
		this.ui.$body = $("body");

		this.ui.$newsletter = $(".js-newsletter");
		this.ui.$footer = $(".js-footer");

		this.ui.$section = $(".js-section");
		this.ui.$summaryToggle = $(".js-summary-toggle");
		this.ui.$summaryClose = $(".js-summary-close");
		this.ui.$summaryItem = $(".js-summary-item");
        this.ui.$summaryProgress = $(".js-summary-item-progress");
	},

	bindEvents: function bindEvents() {
		this.ui.$summaryItem.on('click', this.onSummaryLink.bind(this));
        this.ui.$summaryToggle.on('click', this.onSummaryOpen.bind(this))
        this.ui.$summaryClose.on('click', this.onSummaryClose.bind(this))

        let scrollTimeout = null;
        this.ui.$window.on('scroll', () => {
            if (!scrollTimeout) {
                scrollTimeout = setTimeout(() => {
                    this.onPageScroll();
                    this.setPageProgress();
                    scrollTimeout = null;
                }, 50);
            }
        });
        
        let scrollTimeout2 = null;
        this.ui.$window.on('scroll', () => {
            if (!scrollTimeout2) {
                scrollTimeout2 = setTimeout(() => {
                    this.setSectionBreakpoints();
                    scrollTimeout2 = null;
                }, 1000);
            }
        });


	},

    onSummaryOpen: function(e) {
        this.ui.$body.addClass("disable-scroll");
        $(e.currentTarget).closest('.js-summary').addClass('is-active')
    },

    onSummaryClose: function(e) {
        this.ui.$body.removeClass("disable-scroll");
        $(e.currentTarget).closest('.js-summary').removeClass('is-active')
    },

    setSectionBreakpoints: function() {
        console.log('setSectionBreakpoints')
        this.ui.sectionBreakpoints = []
        this.ui.$section.each((i, e) => {
            let top = $(e).offset().top
            let id = $(e).attr('id')
            
            this.ui.sectionBreakpoints.push({top, id})
        })
    },

	onPageScroll: function() {
        let scroll = this.ui.$window.scrollTop()
        let currentId = null

        this.ui.sectionBreakpoints.forEach(e => {
            if (scroll > (e.top - 110)) {
                currentId = e.id
            }
        })

        let currentSection = this.ui.$summaryItem.filter((i, e) => $(e).attr('href') == ("#" + currentId))
        if (currentSection.length) {
            this.ui.$summaryItem.removeClass('is-active')
            currentSection.addClass('is-active')
        }
        
	},

	setPageProgress: function() {
        let scroll = this.ui.$window.scrollTop()
        let windowHeight = this.ui.$window.height()
        let footerHeight = (this.ui.$newsletter.outerHeight() ?? 0) + (this.ui.$footer.outerHeight() ?? 0)
        let documentHeight = this.ui.$document.height()
        let relativeHeight = documentHeight - footerHeight - windowHeight

        let percentage = scroll / relativeHeight

        this.ui.$summaryProgress.css('transform', 'scaleX(' + percentage + ')')
	},

	onSummaryLink: function(e) {
        console.log('summary click')
        e.preventDefault()
        let target = $(e.currentTarget).attr('href')
        $('html, body').animate({
            scrollTop: $(target).offset().top - 100
        }, 500);
        $(e.currentTarget).closest('.js-summary').removeClass('is-active')
        this.ui.$body.removeClass("disable-scroll");
	},

};

module.exports = summary;
